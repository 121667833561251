"use strict";

$(function () {
  /**
   * Mutation Observer for hubspot exit intent popup
   */
  // Create an observer instance
  var hubspotTimeout;
  var observer = new MutationObserver(function (mutations) {
    var hubspotModalFound = false;
    mutations.forEach(function (mutation) {
      var newNodes = mutation.addedNodes; // DOM NodeList

      if (newNodes !== null) {
        var $nodes = $(newNodes);
        $nodes.each(function () {
          var $node = $(this); // Hubspot exit intent popup class

          if ($node.hasClass("leadinModal")) {
            hubspotModalFound = true;
          }
        });
      }
    });
    if (hubspotModalFound) {
      $(".leadinModal-content-wrapper").trigger('modalVisible');
    } else {
      clearTimeout(hubspotTimeout);
      hubspotTimeout = setTimeout(function () {
        hubSpotDestroyModal();
      }, 10000);
    }
  }); // Configuration of the observer:

  var config = {
    attributes: true,
    childList: true,
    characterData: true
  }; // Pass in the target node, as well as the observer options

  observer.observe(document.body, config);
  $(document).on('modalVisible', ".leadinModal-content-wrapper", function (e) {
    clearTimeout(hubspotTimeout);
    $(this).attr('tabindex', 0);
    setTimeout(function () {
      $(".leadinModal-close").css('outline', 'white').addClass('focus').focus();
    }, 1);
    $(".site-matte").attr('aria-hidden', true);
    $(document).on('keydown', 'body', function (e) {
      // tab outside of modal while open
      if (e.keyCode === 9 && $(e.target).parents('.leadinModal-content').length <= 0) {
        $('.leadinModal-content-wrapper').focus();
      }
    });
  });
  function hubSpotDestroyModal() {
    $(document).off('keydown', 'body');
    $(".site-matte").removeAttr('aria-hidden');
    observer.disconnect();
  }
});
/**
 * Skip navigation to  #main-content popup for accessibility
 */

$(function () {
  var srNav = $('.sr-nav');
  srNav.on('focus mouseenter', function (e) {
    $(this).attr('tabindex', -1).addClass('skip-links-popup fadeIn active').removeClass('sr-only sr-only-focusable');
    ONNIT.cycle_header_messages.stop();
    $('.skip-to-main-content').focus();
  });
  $(window).on('keyup', function (e) {
    var focus = $(document.activeElement);
    if (e.keyCode === 27 && (focus.is('.sr-nav') || srNav.has(focus).length)) {
      removeNavPopup(srNav);
    }
  });
  $("#main-content").attr('tabindex', '-1');
  $(".skip-to-main-content").on('click', function (e) {
    removeNavPopup(srNav);
    setTimeout(function () {
      $("#main-content").focus();
    }, 10);
  });
  srNav.on('focusout mouseout mouseleave', function (e) {
    setTimeout(function () {
      var focus = $(document.activeElement);
      if (!focus.is('.sr-nav') && !srNav.has(focus).length) {
        removeNavPopup(srNav);
      }
    }, 0);
  });
  /* Remove broken implementation of aria tags */

  $(".slick-slide").removeAttr('aria-describedby');
  $(".slick-dots").removeAttr('role');
  $(".slick-dots li").removeAttr('role').removeAttr('aria-controls').removeAttr('aria-selected');
  $("h3.bv-content-title").replaceWith(function () {
    return $('<p />', {
      className: this.className,
      html: $(this).html()
    });
  });
  $(".slick-dots li").each(function (i, elem) {
    var target = $(elem);
    if (!target.hasClass('slick-active')) {
      target.children('button').attr('aria-hidden', 'true').attr('tabIndex', '-1');
    } else {
      target.children('button').removeAttr('aria-hidden').removeAttr('tabIndex');
    }
  });
});
function removeNavPopup(el) {
  $(el).attr('tabindex', 0).removeClass('skip-links-popup fadeIn active').addClass('sr-only sr-only-focusable');
  $('.sr-nav').next().find(':focusable').first().focus();
}
/***
 * Main Navigation Web Accessibility Functionality
 */

$(function () {
  var tabFocusClass = 'tab-focus';
  var openClass = 'is-open';
  var navSelector = '#primary-nav .site-nav-primary-item .site-nav-primary-anchor';
  var menuElemOpen = null;
  var prevEvent = null;
  var primaryNav = $('nav#primary-nav');
  var utilNav = $('.site-util-primary-list'); // Screen Reader (VoiceOver) fix for showing mobile popup menu

  $('#mobile-account-menu-toggle').on('mfpOpen', function (e) {
    $('.mfp-close').attr('aria-label', 'Close Menu');
    $('.site-mobile-label').focus();
    $(e.target).attr('aria-expanded', true);
    $('.site-matte').attr('aria-hidden', true);
    $("iframe#launcher").hide();
  });
  $('#primary-nav-toggle,#site-sidebar-nav-toggle').on('mfpOpen', function (e) {
    $('.mfp-close').attr('aria-label', 'Close Menu');
    $(e.target).attr('aria-expanded', true);
    $('.site-matte').attr('aria-hidden', true);
    $("iframe#launcher").hide();
  });
  $('#primary-nav-toggle,#site-sidebar-nav-toggle').on('mfpClose', function (e) {
    $(e.target).attr('aria-expanded', false);
    $('.site-matte').removeAttr('aria-hidden');
    $("#primary-nav-toggle").focus();
    $("iframe#launcher").show();
  });
  /**
   * Listener to remove focus from main nav. Used to remove child tabFocusClass from nav items
   */

  $(document).on('focusout mouseleave', '#primary-nav.focus', function (e) {
    var active = null; // Hack to get newly active element

    setTimeout(function () {
      active = $(document.activeElement).parents('#primary-nav');
      if (active.length <= 0) {
        primaryNav.trigger('focusRemoved');
      }
    }, 1);
  });
  /**
   * Remove focus from any open menus after tabbing through main nav
   */

  $(document).on('focusRemoved', '#primary-nav', function (e) {
    $(this).removeClass('focus').find('li.site-nav-primary-item.has-secondary').removeClass(tabFocusClass + ' ' + openClass).find('a.site-nav-primary-anchor').attr('aria-expanded', false);
  });
  /**
   * Display auth image of product when tabbing through subnav
   */

  $('.site-nav-secondary-anchor').on('focus', function (e) {
    var target = $(e.target);
    $(target).trigger('mouseenter');
    ONNIT.nav.show_product(null, target);
    primaryNav.addClass('focus');
  });
  /**
   * Display subnav when tabbing through #primary-nav on Desktop
   */

  $(navSelector).on('mouseover focus', function (e) {
    var currentItem = $('li.site-nav-primary-item.has-secondary');
    currentItem.removeClass('tab-focus is-open').find('a.site-nav-primary-anchor').attr('aria-expanded', false);
    var navItem = $(e.target).closest('li.site-nav-primary-item.has-secondary');
    navItem.addClass('tab-focus');
    navItem.find('a.site-nav-primary-anchor').attr('aria-expanded', true);
    primaryNav.addClass('focus');
  });
  /**
   * Hide secondary-nav when leaving #primary-nav menu item
   */

  $(navSelector).on('focusout mouseleave', function (e) {
    var navItem = $(e.target).closest('li.tab-focus');
    if (menuElemOpen !== null && menuElemOpen.get(0) !== navItem.get(0)) {
      focusOutElem(navItem, menuElemOpen, true);
    }
  });
  primaryNav.on('keydown', function (e) {
    var screenSize = ONNIT.nav.state,
      currentNavItem = $('li.site-nav-primary-item.has-secondary' + '.' + tabFocusClass),
      openNavItem = $(e.target).closest('li.site-nav-primary-item').hasClass('toggled-on');
    switch (e.keyCode) {
      // Enter
      case 13:
        if (!currentNavItem.hasClass('is-open')) {
          e.preventDefault();
        }
        if (screenSize === 'smallscreen') {
          if ($(e.target).hasClass('close-mobile-subnav')) {
            ONNIT.nav.sub_toggle(e);
          }
          return;
        }
        var navItem = $(e.target).closest('li');
        focusInElem(navItem, currentNavItem);
        break;
      case 27:
        // Escape
        e.preventDefault();
        if (screenSize !== 'bigscreen' && openNavItem.length > 0) {
          e.preventDefault();
          e.stopImmediatePropagation();
          return;
        }
        prevEvent = 27;
        currentNavItem.removeClass(openClass).children('.site-nav-primary-anchor').attr('aria-expanded', "false").focus();
        prevEvent = null; //focusOutElem(openNavItem, currentNavItem, true);

        break;
      case 40:
        // Down Arrow
        if (screenSize !== 'bigscreen' && currentNavItem.find('.subnav').is(':visible')) {
          return;
        }
        break;
      case 39:
        e.preventDefault(); // Right Arrow

        if (!currentNavItem.is(":last-child")) {
          primaryNav.trigger('focusRemoved');
          currentNavItem.next().children('a.site-nav-primary-anchor').focus();
        }
        break;
      case 35:
        // End Button
        e.preventDefault();
        currentNavItem.find('.subnav .site-nav-secondary-item:last a').focus();
        break;
      case 36:
        // Home Button
        e.preventDefault(); // Focus on first li in subnav

        currentNavItem.find('.subnav .site-nav-secondary-item:visible:first a').addClass('focused-item').focus();
        break;
      case 38:
        // Up Arrow
        if (screenSize !== 'bigscreen' && currentNavItem.find('.subnav').is(':visible')) {
          return;
        }
        break;
      case 37:
        // Left Arrow
        e.preventDefault();
        if (!currentNavItem.is(":first-child")) {
          currentNavItem.prev().children('a.site-nav-primary-anchor').focus();
        }
        break;
      default:
        break;
    }
  });
  utilNav.on('keydown', function (e) {
    var currentNavItem = $(':focus').closest('li.site-util-primary-item');
    switch (e.keyCode) {
      case 27:
        // Escape
        e.preventDefault();
        prevEvent = 27;
        currentNavItem.children().each(function (i, elem) {
          $(elem).blur();
        });
        currentNavItem.removeClass('is-open');
        currentNavItem.children('a.site-util-primary-anchor').first().focus().attr('aria-expanded', false);
        prevEvent = null;
        break;
      case 37:
        // Left Arrow
        e.preventDefault();
        if (currentNavItem.is(":first-child")) {
          primaryNav.find('li.menu-item.site-nav-primary-item:visible:last-child a.site-nav-primary-anchor').focus();
        } else {
          currentNavItem.prev('li.site-util-primary-item').children('a.site-util-primary-anchor').focus();
        }
        break;
      case 39:
        e.preventDefault(); // Right Arrow

        currentNavItem.next('li.site-util-primary-item').children('a.site-util-primary-anchor').focus();
        break;
    }
  });
  function focusInElem(elem, elemOpen) {
    var screenSize = ONNIT.nav.state;
    if (elem.length > 0 || elem.get(0) !== elemOpen.get(0)) {
      elemOpen.removeClass(tabFocusClass + ' ' + openClass).trigger('mouseleave');
      /*if (screenSize !== 'smallscreen') {
          elemOpen.find('a.site-nav-primary-anchor').attr('aria-expanded', '');
      }*/
    }

    elem.addClass(tabFocusClass + ' ' + openClass).trigger('mouseenter'); //if (screenSize !== 'smallscreen') {

    elem.find('a.site-nav-primary-anchor').attr('aria-expanded', true); //}
    // focus on first item in subnav
    //$('.' + tabFocusClass + ' .subnav').find('.site-nav-secondary-item:visible:first a').focus();
  }

  function focusOutElem(elem, elemOpen, force) {
    force = force || false; // Get the focusedOut li

    if (force || elem.length > 0 && elem.get(0) !== elemOpen.get(0)) {
      elemOpen.removeClass(tabFocusClass + ' ' + openClass).trigger('mouseleave');
      elem.find('.site-nav-primary-anchor').attr('aria-expanded', false);
      elemOpen.find('a.site-nav-primary-anchor').attr('aria-expanded', false);
      return true;
    }
    return false;
  }
  $('.magnific-ada').magnificPopup({
    type: 'inline',
    autoFocusLast: true,
    closeBtnInside: true,
    focus: ".modal-title",
    fixedContentPos: true,
    fixedBGPos: true,
    removalDelay: 250,
    closeOnContentClick: false,
    showCloseBtn: true,
    callbacks: {
      open: function open() {
        $(".site-matte").attr('aria-hidden', true);
        $('.modal-title').attr('tabindex', -1).css('outline', 'transparent').focus();
      },
      afterClose: function afterClose() {
        $(".site-matte").removeAttr('aria-hidden');
        $('.modal-title').removeAttr('tabindex');
      }
    }
  });
});
(function ($) {
  jQuery.extend(jQuery.expr[':'], {
    focusable: function focusable(el, index, selector) {
      var $element = $(el);
      return $element.is(':input:enabled, a[href], area[href], object, [tabindex]') && !$element.is(':hidden');
    }
  });
  function focusOnElement($element) {
    if (!$element.length) {
      return;
    }
    if (!$element.is(':focusable')) {
      // add tabindex to make focusable and remove again
      $element.attr('tabindex', -1).on('blur focusout', function () {
        $(this).removeAttr('tabindex');
      });
    }
    $element.focus();
  }
  $(document).ready(function () {
    // if there is a '#' in the URL (someone linking directly to a page with an anchor)
    if (document.location.hash) {
      try {
        focusOnElement($(document.location.hash));
      } catch (error) {// document.location.hash is not a valid selector
      }
    } // if the hash has been changed (activation of an in-page link)

    $(window).bind('hashchange', function () {
      var hash = "#" + window.location.hash.replace(/^#/, '');
      focusOnElement($(hash));
    });
  });
})(jQuery); // ADA for friendbuy ticker

(function ($) {
  var globalHeader = $('.global-header-items');
  globalHeader.on("focusin a", function (e) {
    ONNIT.cycle_header_messages.stop();
  });
  globalHeader.on('keydown', function (e) {
    switch (e.keyCode) {
      // left arrow
      case 37:
        ONNIT.cycle_header_messages.prev();
        break;
      // right arrow

      case 39:
        ONNIT.cycle_header_messages.next();
        break;
    }
  });
})(jQuery); // remove aria attributes from footer for desktop headers

(function ($) {
  function footerFix() {
    var screenSize = "bigscreen";
    if (window.matchMedia('screen and (max-width: 739px)').matches) {
      screenSize = "smallscreen";
    }
    var target = $("footer .site-footer-expand-trigger");
    if (screenSize !== "smallscreen") {
      target.each(function (i, elem) {
        $(elem).removeAttr("role").removeAttr("aria-expanded");
      });
    } else {
      target.each(function (i, elem) {
        $(elem).attr("role", "button").attr("aria-expanded", false);
      });
    }
  }
  footerFix();
  $(window).resize(function () {
    footerFix();
  });
})(jQuery);